.navbar {
    margin-bottom: 0;
}
body {
    background: url('/img/dark_exa.png') repeat;
    background-color: black;
}

h3{
    
    margin-top: 30px;
    .with-highlight {
        text-align: center;
        color: $text-secondary;
        text-transform: none;
        font-weight: normal;
        text-transform: capitalize;
        
        span {
            color: $highlight;
            text-transform: capitalize;
        }
    }
}

.page-header {
    padding: 15px 0;
    margin: 0;
    border-bottom: none;
    .container {
        max-width: $content-width;
        margin: auto;
    }
    .title-icon {
        text-align: center;
        svg {
            width: 45px;
            height: 45px;
            margin-top: 20px;
        }
    }
    h1 {
        text-align: center;
        color: $text-secondary;
        text-transform: none;
        font-size: 22px;
        line-height: 25px;
        font-weight: normal;
        text-transform: capitalize;
        margin-top: 0px;

        @media(min-width: 992px){
            font-size: 30px;
            line-height: 35px;
        }
    }

    h3 {
        color: #9c9c9c;
        font-weight: 100;
        font-size: 16px;
        margin-top: 0px;
        text-align: center;
        margin-bottom: 0;
        
        @media(min-width: 992px){
            font-size: 20px;
        }
        &:after {
            content: "";
            background: url(/img/arrow.png) no-repeat;
            width: 90px;
            height: 30px;
            display: block;
            margin: 20px auto 0;
            border-radius: 5px;
        }
    }
    h1 span, h3 span {
        color: $highlight;
        text-transform: capitalize;
    }

    h1 small{
        text-transform: capitalize;
    }

    h1 small {
        color: $text-secondary;
    }

    .progressbar-container{
        width: 310px;
        margin: 0 auto;
        @media(min-width: 992px){
            width: 51%;
            margin: 0 auto;
        }

        .progress {
            height: 6px;
            background-color: #303030;
            margin-bottom: 0;
        .progress-bar {
                background-color: $highlight;
            }
        }
    }
}

.page-body {
    // max-width: $content-width + 200px;
    margin: auto;
    // background: $background-normal;
    .container {
        max-width: $content-width;
        margin: auto;
        padding: 25px 20px;

        label {
            font-size: 1.3em;
            font-weight: normal;
            color: $text-secondary;
            span {
                color: $highlight;
            }
        }

        input, select, textarea, .rbt-input-multi, .rbt-input {
            border: 1px solid $highlight;
            font-size: 16px;
            padding: 12px;
            height: auto;
            border-radius: 0;
            color: $text-primary;
            background: #000;
        }

        .rbt-token {
            background-color: transparent;
            color: $highlight;
            border: 1px solid $highlight;
            margin: 5px;
        }

        select {
            height: 50px;
        }

        .btn-primary {
            color: $text-secondary;
            background-color: $highlight;
            border: none;
            border-radius: 3px;
            padding: 8px 35px 8px 10px;
            @media(min-width: 992px){
                padding: 8px 75px 8px 30px;
            }
            position: relative;
            &:hover {
                background-color: #eab327;
            }
            &.attachment{
                padding: 8px 55px;
                text-transform: none;
            }
        }
        .login-form {
            .btn-primary {
                padding: 8px 75px;
            }

            .login-container{
                @media(min-width: 700px){
                    margin: 0 auto;
                }
            }
        }

        .btn-inverted {
            color: $text-primary;
            background-color: rgba(255, 255, 255, 0);
            border: 1px solid #eab327;
            border-radius: 3px;
            padding: 7px 10px 7px 35px;
            position: relative;
            @media(min-width: 992px){
                padding: 7px 30px 7px 75px;
            }
            &:hover {
                background-color:#eab327;
                color: #fff;
            }
        }

        .login-feedback, .signup-feedback{
            background-color: red;
            padding: 5px 15px 5px 15px;
            margin: 20px;
            border-radius: 3px;
            width: fit-content;
        }

        .icon {
            color: $highlight;
            width: 20px;
            &.pass {
                color: greenyellow;
            }
            &.fail {
                color: red;
            }
            &.neutral {
                color: rgb(63, 62, 62);
            }
        }
        div {
            color: $text-secondary;
        }
        .tag {
            width: auto;
            padding: 5px !important;
            border-radius: 15px !important;
            font-size: 1em !important;
            min-width: auto !important;
            min-height: auto !important;
            margin-right: 5px;
            background-color: transparent !important;
            border: 2px solid $highlight !important;
            &.active {
                background-color: $highlight !important;
            }
        }
    }
}

.react-grid-Toolbar {
    height: 65px;
}

.progress.file-progress {
    background-color: #303030;
    height: 45px;
    width: 425px;
    border: 1px solid $highlight;
    border-radius: 0;
    float: left;
   .progress-bar {
        background-color: #443206;
        line-height: 45px;
        vertical-align: middle;
    }
}

.modal-body .btn {
    border-radius: 3px !important;
}