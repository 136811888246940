$i: 5;
@while $i < 101 {
  @each $direction, $class in (top, t), (right, r), (bottom, b), (left, l) {
    .p#{$class}-#{$i} {
      padding-#{$direction}: (1%) * $i;
    }
    .m#{$class}-#{$i} {
      margin-#{$direction}: 1% * $i;
    }
  }
  $i: $i + 5;
}

@each $type in (padding, margin) {
  @each $size, $multiplier in (zero, 0), (tiny, 0.5), (small, 1), (normal, 1.5), (medium, 2), (large, 3), (xlarge, 5) {
    @each $direction in (top, bottom, left, right) {
      .#{$type}-#{$direction}-#{$size} {
        #{$type}-#{$direction}: $spacer * $multiplier !important;
      }
    }
    .#{$type}-horiz-#{$size} {
      #{$type}-left: $spacer * $multiplier !important;
      #{$type}-right: $spacer * $multiplier !important;
    }
    .#{$type}-vert-#{$size} {
      #{$type}-top: $spacer * $multiplier !important;
      #{$type}-bottom: $spacer * $multiplier !important;
    }
    .#{$type}-all-#{$size} {
      #{$type}: $spacer * $multiplier !important;
    }
  }
}

.margin-horiz-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}