.help-block {
  font-size:14px;
  line-height: 24px;
  font-weight: 300;
  &.help-error {
    color: $red;
  }
}

.has-error span.help-block{
  color: $red;
}

.rbt-input-hint {
  font-weight: 300;
}

input[type=file] {
  &.custom-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &+label {
      color: $white;
      background: $orange;
      border: 1px solid $orange;
      min-width: 175px;
      border-radius: 50px;
      font-size: 15px;
      font-weight: 600;
      width: auto;
      display: inline-block;
      padding: 8px 55px !important;
      margin-top: $spacer;
      margin-bottom: $spacer;
      cursor: pointer;
      vertical-align: middle;
      white-space:nowrap;
      text-align: center;
      &.purple {
        background: $purple;
        border-color: $purple;
      }
    }
  }
}

input {
  &.datepicker {
    &[type=text], &[type=number] {
      outline: none;
      box-shadow: none;
      border: 2px solid $formgrey;
      border-radius: 4px;
      height: 50px;
      font-size: 22px;
      background-color: $formgrey;
      color: $grey;
      font-weight: 300;
      &:focus {
        box-shadow: none;
      }
    }
    &[type=number] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }
    }
  }
}

.control-label {
  &.price-header {
    font-size: 14px;
  }
}