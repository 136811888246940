button {
  &:focus, &:active, &:focus:active {
    outline: none;
    box-shadow: none;
  }
  &.plain-button {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    color: $grey;
    &:hover {
      background: none;
      color: $darkgrey;
    }
  }
}
a {
  &.btn {
    line-height: 38px !important;
  }
}
.btn {
  width: auto;
  display: inline-block;
  padding: 6px 30px;
  margin-top: $spacer;
  margin-bottom: $spacer;
  &:focus, &:active, &:focus:active {
    outline: none;
    box-shadow: none;
  }
  &.btn-auto {
    width: auto !important;
    min-width: 0px !important;
  }
  &.btn-purple {
    background-color: $purple;
    border-color: $purple;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: $white;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    &:hover, &:focus {
      background-color: $darkpurple;
      border-color: $darkpurple;
    }
  }
  &.btn-purple-secondary {
    background-color: $white;
    border: 3px solid $purple;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: $purple;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    &:hover, &:focus {
      background-color: $white;
      border: 3px solid $darkpurple;
      color: $darkpurple;
    }
    &.selected {
      background-color: $orange;
      border-color: $orange;
      color: $white;
      font-weight: 600;
      &:hover, &:focus {
        background-color: $darkorange;
        border-color: $darkorange;
      }
    }
  }
  &.btn-orange {
    background-color: $orange;
    border-color: $orange;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: $white;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    &:hover, &:focus {
      background-color: $darkorange;
      border-color: $darkorange;
    }
  }
  &.btn-orange-secondary {
    background-color: $white;
    border-color: $orange;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: $orange;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    &:hover, &:focus {
      background-color: $darkorange;
      border-color: $darkorange;
      color: $white;
    }
  }
  &.btn-green {
    background-color: $green;
    border-color: $green;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: $white;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    &:hover, &:focus {
      background-color: $darkgreen;
      border-color: $darkgreen;
    }
  }
}

a {
  &.plain-link {
    color: $grey;
    text-decoration: none;
    cursor: pointer;
    &:focus, &:active, &:focus:active {
      color: $darkgrey;
      outline: none;
      box-shadow: none;
    }
    &:hover {
      color: $darkgrey;
      text-decoration: none;
    }
  }
}

.resource-step {
  z-index: 100;
}