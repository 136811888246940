.header {
    background-color: black;
    padding: 60px 20% 10px 20%;
}

.header .picture {
    width: 80%;
    height: 0;
    padding-bottom: 80%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin: 0 auto 30px auto;
}

@media(min-width: 1200px){
    .header .picture {
        width: 300px;
        height: 300px;
        padding-bottom: 0;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        margin: 0 auto 30px auto;
    }
}

.header .info-column {
    position: relative;
}

.header .info-column .edit {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #C89514;
    cursor: pointer;
}

.header .info-column .logout:hover {
    cursor: pointer;
}

.header .title {
    font-size: 45px;
    font-weight: bold;
}

.header .blurb {
    color: white;
    font-size: 18px;
}

.header .attributes {
    padding: 20px 0;
    font-size: 18px;
}

.header .attributes .attribute {
    align-items: center;
}

.header .attributes .attribute .attr-icon {
    padding: 4px 8px;
    display: inline-block;
}

.header .attributes .attribute .attr-text {
    color: white;
    display: inline-block;
}

@media(max-width: 992px){
    .header{
        text-align: center;
    }

    .header .info-column .edit{
        left: 0;
        right: 0;
        top: -90px;
        background-color: rgba(0,0,0, .5);
        width: 100px;
        margin: 0 auto;
        padding: 12px;
        cursor: pointer;
        border-radius: 25px;
        transition: all .3s;
    }

    .header .info-column .edit:hover{
        background-color: rgba(0,0,0, .8);
    }
}

@media(max-width: 768px){
    .header{
        padding: 60px 10% 10px 10%;
    }
    .header .title{
        font-size: 28px;
    }

    
}