//variables

//backgrounds
$background-primary: #0F0F0F;
$background-secondary: #161616;
$background-normal: #0D0D0D;

//text
$text-primary: #B5812B;
$text-secondary: #F6F6F6;
$text-normal: #000;

//highlights
// $highlight: #B5812B;
$highlight: #C89514;

//Sizing
$content-width: 1000px;

//nav
$nav-icon: $text-primary;
$nav-text: $text-primary;

//other
$green: #15D3BC;
$darkgreen: #10a291;
$purple: #727ce2;
$darkpurple: #636bc3;
$orange: #f4a62f;
$darkorange: #da880c;
$formgrey: #EEEDED;
$placeholdergrey: #A9A9A9;
$lightgrey: #F5F0EC;
$grey: #7d8484;
$darkgrey: #333333;
$black: #000000;
$white: #ffffff;
$red: #EE4256;

$emergencyMainColor: #fb6475;
$emergencyLightColor: #ff929f;
$emergencyStrongColor: #ee4156;
$purchasesMainColor: #f4a622;
$purchasesLightColor: #ffbb4c;
$purchasesStrongColor: #f78615;
$retirementMainColor: #c8bf28;
$retirementLightColor: #e3da43;
$retirementStrongColor: #b5ab0b;

$colors: (
  "green": $green,
  "darkgreen": $darkgreen,
  "purple": $purple,
  "darkpurple": $darkpurple,
  "orange": $orange,
  "darkorange": $darkorange,
  "red": $red,
  "formgrey": $formgrey,
  "placeholdergrey": $placeholdergrey,
  "lightgrey": $lightgrey,
  "grey": $grey,
  "darkgrey": $darkgrey,
  "black": $black,
  "white": $white,
  "emergencyMainColor": $emergencyMainColor,
  "emergencyLightColor": $emergencyLightColor,
  "emergencyStrongColor": $emergencyStrongColor,
  "purchasesMainColor": $purchasesMainColor,
  "purchasesLightColor": $purchasesLightColor,
  "purchasesStrongColor": $purchasesStrongColor,
  "retirementMainColor": $retirementMainColor,
  "retirementLightColor": $retirementLightColor,
  "retirementStrongColor": $retirementStrongColor
);

.emergencyMainColor {
  color: #fb6475;
 }
 .emergencyLightColor {
  color: #ff929f
 }
 .emergencyStrongColor {
  color: #ee4156
 }
 .purchasesMainColor {
  color: #f4a622
 }
 .purchasesLightColor {
  color: #ffbb4c
 }
 .purchasesStrongColor {
  color: #f78615
 }
 .retirementMainColor {
  color: #c8bf28
 }
 .retirementLightColor {
  color: #e3da43
 }
 .retirementStrongColor {
  color: #b5ab0b
 }

$spacer: 10px;

//text and background colors
@each $name, $hex in $colors {
  .text-#{$name} {
    color: $hex !important;
  }
  .bg-#{$name} {
    background-color: $hex !important;
  }
}

@mixin menu-shadow {
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2),
              0 8px 10px 1px rgba(0,0,0,0.14),
              0 3px 14px 2px rgba(0,0,0,0.12);
}

$opacity: 100;
@while $opacity > 0 {
  .opacity-#{$opacity} {
    opacity: $opacity / 100;
  }
  $opacity: $opacity - 10;
}