.profile-manager {
    background-color: white;
}

.highlight {
    color: #C89514;
}

.profile-manager .info-columns {
    display: flex;
    padding: 20px 20%;
}

.profile-manager .info-columns .status-column {
    flex: 35;
    margin-top: 30px;
}

.profile-manager .info-columns .main-column {
    flex: 65;
    margin: 30px 0 0 20px;
}

@media(max-width: 992px){
    .profile-manager .info-columns {
        display: block;
        padding: 0;
    }

    .profile-manager .info-columns .status-column {
        padding: 0 22px;
    }
}