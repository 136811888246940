body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: $darkgrey;
  &.x2 {
    font-size: 36px;
    line-height: 46px;
    font-weight: 600;
    color: $darkgrey;
  }
}
h2 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}
// p, label {
  .intro {
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
    letter-spacing: 1px;
    color: $grey;
    margin: 25px 0;
  }
// }

p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
}

//font sizes
.text-small {
  font-size: 14px;
  line-height: 18px;
}

.text-tiny {
  font-size: 10px;
  line-height: 14px;
}

//font styles
.fw-bold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-normal {
  font-weight: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

//vertical alignment
.align-middle {
  vertical-align:middle !important;
}
.align-top {
  vertical-align:top !important;
}
.align-bottom {
  vertical-align:bottom !important;
}
.align-baseline {
  vertical-align:baseline !important;
}

//display types
.inline {
  display: inline !important;
}
.inline-block {
  display: inline-block !important;
}
.block {
  display: block !important;
}

.d-none {
  display: none !important;
}