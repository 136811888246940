
.signup-wizard {
    .page-body a {
        color: $highlight;
        text-decoration: underline;
    }
    .dropdown-menu {
        border-radius: 0;
    }
    a.dropdown-item {
        text-decoration: none;
        color: $background-secondary;
        font-size: 18px;
    }
    .nav-controls {
        margin: 0 20px;
    }
    .spaced {
        margin: auto;
        @media(min-width: 992px){
            width: 70%;
        }
        .form-group {
            .col-sm-6 {
                padding-left: 0;
            }
            >label {
                margin-bottom: 20px;
            }
            .answer-options{
                @media(min-width: 992px){
                    margin-left: 40px;
                }
            }
            /*
            ***Commenting this out for now. The task says "Remove bullets" just want to make sure this is it before I remove them altogether.***
            
            >label:before {
                content: "";
                display: block;
                position: absolute;
                margin-left: -109px;
                margin-top: 0px;
                border-radius: 7px;
                background-image: url(/img/arrow-head.png);
                width: 26px;
                height: 20px;
            }
            */
        }
    }

    .typeahead-close-container {
        position: relative;
    }

    .typeahead-close {
        position: absolute;
        top: 0; bottom: 0; right: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }


    .radio-select {
        background-color: transparent;
        border: 1px solid $highlight;
        padding: 6px 16px;
        color: $highlight;
        border-radius: 3px;
        min-width: 100%;
        font-weight: bold;
        margin: 4px 0;
        
        @media(min-width: 992px){
            background-color: transparent;
            border: 1px solid $highlight;
            padding: 6px 16px;
            color: $highlight;
            border-radius: 3px;
            min-width: 80px;
            margin-right: 10px;
            font-weight: bold;
        }
        
        &:hover {
            border-color: #f7b241;
            color: #f7b241;
        }
        &.active {
            background-color: $highlight;
            color: white;
        }
    }
}
