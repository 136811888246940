.d-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex: 1 1 auto;
  flex-direction: row;
}


//horizontal alignment
.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

//vertical alignment
.align-items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.reverse-column {
  display: flex;
  flex-direction: column-reverse;
}