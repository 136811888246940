.category {
    position: relative;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #eee;
    padding: 1px 15px 15px 15px;
}

.status-column .category {
    border: none;
    background-color: #f6f6f6;
    border-radius: 8px;
    padding-bottom: 1px;
}

.status-column .category .edit {
    background-color: #fff;
}

.category .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.category .edit {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-color: #f6f6f6;
    padding: 5px 10px;
    border-radius: 8px;
    transition: all .3s;
}
.category .edit:hover {
    background-color: #eee;
    transition: all .3s;
}

.category .attributes.two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.category .attributes .attribute {
    margin-bottom: 14px;
}

.category .attributes .attribute .attr-title {
    font-size: 16pt;
    margin-bottom: 5px;
}

.category .attributes .attribute .attr-body {
    font-size: 12pt;
}

.category .attributes .attribute .attr-row.bullet {
    display: list-item;
    list-style-type: disc;
    margin-left: 20px;
}

.category .attributes .attribute .attr-row.subtitle {
    font-weight: bold;
    margin-top: -.25em;
    margin-bottom: 1em;
}

.category .attributes .attribute .attr-row.indent {
    margin-left: 2em;
    list-style-type: circle;
}

.category .attributes .attribute .attr-row.subtitle.location {
    font-style: italic;
}

.category .attributes .attribute .attr-row .attr-row-icon {
    margin: 0 .5em;
}

@media(max-width: 992px){
    .category .title {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .category .edit {
        position: relative;
        top: 0;
        right: 0;
        margin: 10px 0;
        width: 70px;
    }

    .category .attributes .attribute .attr-title {
        font-size: 15pt;
        margin-bottom: 5px;
    }
}