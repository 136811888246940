
.wizard-nav {
    display: inline-block;
    margin-bottom: 15px;
    li {
        display: inline-block;
        padding: 4px;
        @media(min-width: 991px){
            padding: 10px 10px 5px 10px;
        }

        &:hover {
            opacity: 0.7;
        }
        a {
            display: block;
            text-decoration: none;
        }

        svg{
            width: 25px;
            height: 25px;
            @media(min-width: 991px){
                width: 40px;
                height: 40px;
            }
        }
        // a.active:after, a:hover:after {
        //     content: "";
        //     border-bottom: 5px solid $highlight;
        //     display: block;
        //     margin-top: 22px;
        //     width: 140%;
        //     margin-left: -20%;
        //     border-radius: 5px;
        // }
    }

    .nav-item-label{
        display: block;
        font-size: 7px;
        font-weight: 600;
        margin-top: 10px;
        color: $text-secondary;
        @media(min-width: 991px){
            font-size: 12px;
        }
    }

    // &.container-fluid:after {
    //     content: "";
    //     border: 3px solid;
    //     width: 88%;
    //     margin-left: 6%;
    //     margin-right: 6%;
    //     margin-top: -22px;
    //     border-radius: 5px;
    // }
}
