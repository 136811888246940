.form-control{
    border: 1px solid #C89514;
    font-size: 16px;
    padding: 12px;
    height: auto;
    border-radius: 0;
    color: #B5812B;
    background: transparent;
}

.help-block{
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: red;
}

.login-container{
    li{
        font-size: 13px;
    }
    h3{
        font-size: 18px;
        &.bold-n-gold{
            color: #C89514;
            font-weight: bold;
        }
    }
}