.wizard-steps {
  margin-top: 47px;
  margin-bottom: 40px;
}
.wizard-step-1-3 {
  border: 1px solid #727ce2;
  width: 33.3%;
  display: inline-block;
}
.wizard-step-1-3:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 53px;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
  border: 2px solid #727ce2;
}
.wizard-step-1-3:first-child:before {
  left: 0%;
}
.wizard-step-1-3:last-child:before {
  left: 100%;
}
.wizard-step-1-3.done:before {
  background-color: #727ce2;
}
.divider {
  width: 80px;
  border: 2px solid #727ce2
}

.react-datepicker {
  font-size: 1.2rem;
  border-color: #727ce2 !important;
}
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
}
.react-datepicker__current-month {
  font-size: 1.144rem;
}
.react-datepicker__day {
  width: 2.5rem;
  line-height: 2rem;
  color: #727ce2 !important;
  font-weight: 600;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: #f4a62f !important;
  color: #ffffff !important;
}
.react-datepicker__day-name {
  width: 2.5rem !important;
  color: #ffffff !important;
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__current-month {
  color: #ffffff !important;
}
.react-datepicker__navigation--next {
  border-left-color: #ffffff !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ffffff !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #727ce2 !important;
}
.datepicker {
  width: 96%;
  padding: 5px;
}
.react-datepicker__header {
  background-color: #727ce2 !important;
}
.react-grid-Container{
  width: 100% !important;
}
.react-grid-Toolbar .btn{
  margin-top: 0;
}
