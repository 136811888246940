

.profile-manager {
    background-color: white;
}

.highlight {
    color: #C89514;
}

.profile-manager .info-columns {
    display: flex;
    padding: 20px 20%;
}

.profile-manager .info-columns .status-column {
    flex: 35 1;
    margin-top: 30px;
}

.profile-manager .info-columns .main-column {
    flex: 65 1;
    margin: 30px 0 0 20px;
}

@media(max-width: 992px){
    .profile-manager .info-columns {
        display: block;
        padding: 0;
    }

    .profile-manager .info-columns .status-column {
        padding: 0 22px;
    }
}
.header {
    background-color: black;
    padding: 60px 20% 10px 20%;
}

.header .picture {
    width: 80%;
    height: 0;
    padding-bottom: 80%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin: 0 auto 30px auto;
}

@media(min-width: 1200px){
    .header .picture {
        width: 300px;
        height: 300px;
        padding-bottom: 0;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        margin: 0 auto 30px auto;
    }
}

.header .info-column {
    position: relative;
}

.header .info-column .edit {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #C89514;
    cursor: pointer;
}

.header .info-column .logout:hover {
    cursor: pointer;
}

.header .title {
    font-size: 45px;
    font-weight: bold;
}

.header .blurb {
    color: white;
    font-size: 18px;
}

.header .attributes {
    padding: 20px 0;
    font-size: 18px;
}

.header .attributes .attribute {
    align-items: center;
}

.header .attributes .attribute .attr-icon {
    padding: 4px 8px;
    display: inline-block;
}

.header .attributes .attribute .attr-text {
    color: white;
    display: inline-block;
}

@media(max-width: 992px){
    .header{
        text-align: center;
    }

    .header .info-column .edit{
        left: 0;
        right: 0;
        top: -90px;
        background-color: rgba(0,0,0, .5);
        width: 100px;
        margin: 0 auto;
        padding: 12px;
        cursor: pointer;
        border-radius: 25px;
        transition: all .3s;
    }

    .header .info-column .edit:hover{
        background-color: rgba(0,0,0, .8);
    }
}

@media(max-width: 768px){
    .header{
        padding: 60px 10% 10px 10%;
    }
    .header .title{
        font-size: 28px;
    }

    
}
.rbt-input-main, .rbt-input-multi, .form-control {
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #C89514;
  color: black;
  padding: 8px;
  height: auto;
  /* margin-bottom: 32px; */
}

.field-container {
  margin: 20px 0;
}

.field-container .field-label:nth-child(n+3) {
  padding-top: 15px;
}

.field-label {
  font-size: 16px;
  color: black;
  margin-bottom: 8px;
}

.field-label.field-inline {
  display: inline-block;
  margin-right: 1em;
}

.small-toggle {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  color: #C89514;
}

.rbt-token {
  background-color: transparent;
  color: #C89514;
  border: 1px solid #C89514;
  border-radius: 0;
  margin: 5px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  padding: 20px;
  border-radius: 0;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.title {
  color: #C89514;
  font-size: 20px;
  margin-bottom: 16px;
}

.actions .btn {
  margin-left: 5px;
}

.btn-primary {
  color: #fff;
  background-color: #C89514;
  border: none;
  border-radius: 0;
  padding: 10px 55px;
  text-transform: uppercase;
  position: relative;
}

.btn-primary:hover {
  background-color: #eab327;
}

.btn-inverted {
  color: #eab327;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #eab327;
  border-radius: 0;
  padding: 9px 55px;
  text-transform: uppercase;
  position: relative;
}

.btn-inverted:hover {
  background-color:#eab327;
  color: #fff;
}

.btn.btn-default .svg-inline--fa {
  margin-right: 10px;
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.checkbox-container.toggle {
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}

.field-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;
  cursor: pointer;
}

.field-checkbox.checkbox-active {
  color: #C89514;
}

.field-checkbox.checkbox-active .checkbox-label span {
  border-bottom: 2px solid #C89514;
}

.checkbox-icon {
  font-size: 16px;
  margin: 5px;
}

.checkbox-label {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
}

.remove-button {
  margin-top: 6px;
}

.add-button, .remove-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-button .btn, .remove-button .btn {
  border: none;
  background-color: transparent;
  color: #C89514;
}
.category {
    position: relative;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #eee;
    padding: 1px 15px 15px 15px;
}

.status-column .category {
    border: none;
    background-color: #f6f6f6;
    border-radius: 8px;
    padding-bottom: 1px;
}

.status-column .category .edit {
    background-color: #fff;
}

.category .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.category .edit {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-color: #f6f6f6;
    padding: 5px 10px;
    border-radius: 8px;
    transition: all .3s;
}
.category .edit:hover {
    background-color: #eee;
    transition: all .3s;
}

.category .attributes.two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.category .attributes .attribute {
    margin-bottom: 14px;
}

.category .attributes .attribute .attr-title {
    font-size: 16pt;
    margin-bottom: 5px;
}

.category .attributes .attribute .attr-body {
    font-size: 12pt;
}

.category .attributes .attribute .attr-row.bullet {
    display: list-item;
    list-style-type: disc;
    margin-left: 20px;
}

.category .attributes .attribute .attr-row.subtitle {
    font-weight: bold;
    margin-top: -.25em;
    margin-bottom: 1em;
}

.category .attributes .attribute .attr-row.indent {
    margin-left: 2em;
    list-style-type: circle;
}

.category .attributes .attribute .attr-row.subtitle.location {
    font-style: italic;
}

.category .attributes .attribute .attr-row .attr-row-icon {
    margin: 0 .5em;
}

@media(max-width: 992px){
    .category .title {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .category .edit {
        position: relative;
        top: 0;
        right: 0;
        margin: 10px 0;
        width: 70px;
    }

    .category .attributes .attribute .attr-title {
        font-size: 15pt;
        margin-bottom: 5px;
    }
}

.wizard-steps {
  margin-top: 47px;
  margin-bottom: 40px;
}
.wizard-step-1-3 {
  border: 1px solid #727ce2;
  width: 33.3%;
  display: inline-block;
}
.wizard-step-1-3:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 53px;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
  border: 2px solid #727ce2;
}
.wizard-step-1-3:first-child:before {
  left: 0%;
}
.wizard-step-1-3:last-child:before {
  left: 100%;
}
.wizard-step-1-3.done:before {
  background-color: #727ce2;
}
.divider {
  width: 80px;
  border: 2px solid #727ce2
}

.react-datepicker {
  font-size: 1.2rem;
  border-color: #727ce2 !important;
}
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
}
.react-datepicker__current-month {
  font-size: 1.144rem;
}
.react-datepicker__day {
  width: 2.5rem;
  line-height: 2rem;
  color: #727ce2 !important;
  font-weight: 600;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: #f4a62f !important;
  color: #ffffff !important;
}
.react-datepicker__day-name {
  width: 2.5rem !important;
  color: #ffffff !important;
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__current-month {
  color: #ffffff !important;
}
.react-datepicker__navigation--next {
  border-left-color: #ffffff !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ffffff !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #727ce2 !important;
}
.datepicker {
  width: 96%;
  padding: 5px;
}
.react-datepicker__header {
  background-color: #727ce2 !important;
}
.react-grid-Container{
  width: 100% !important;
}
.react-grid-Toolbar .btn{
  margin-top: 0;
}


.emergencyMainColor {
  color: #fb6475; }

.emergencyLightColor {
  color: #ff929f; }

.emergencyStrongColor {
  color: #ee4156; }

.purchasesMainColor {
  color: #f4a622; }

.purchasesLightColor {
  color: #ffbb4c; }

.purchasesStrongColor {
  color: #f78615; }

.retirementMainColor {
  color: #c8bf28; }

.retirementLightColor {
  color: #e3da43; }

.retirementStrongColor {
  color: #b5ab0b; }

.text-green {
  color: #15D3BC !important; }

.bg-green {
  background-color: #15D3BC !important; }

.text-darkgreen {
  color: #10a291 !important; }

.bg-darkgreen {
  background-color: #10a291 !important; }

.text-purple {
  color: #727ce2 !important; }

.bg-purple {
  background-color: #727ce2 !important; }

.text-darkpurple {
  color: #636bc3 !important; }

.bg-darkpurple {
  background-color: #636bc3 !important; }

.text-orange {
  color: #f4a62f !important; }

.bg-orange {
  background-color: #f4a62f !important; }

.text-darkorange {
  color: #da880c !important; }

.bg-darkorange {
  background-color: #da880c !important; }

.text-red {
  color: #EE4256 !important; }

.bg-red {
  background-color: #EE4256 !important; }

.text-formgrey {
  color: #EEEDED !important; }

.bg-formgrey {
  background-color: #EEEDED !important; }

.text-placeholdergrey {
  color: #A9A9A9 !important; }

.bg-placeholdergrey {
  background-color: #A9A9A9 !important; }

.text-lightgrey {
  color: #F5F0EC !important; }

.bg-lightgrey {
  background-color: #F5F0EC !important; }

.text-grey {
  color: #7d8484 !important; }

.bg-grey {
  background-color: #7d8484 !important; }

.text-darkgrey {
  color: #333333 !important; }

.bg-darkgrey {
  background-color: #333333 !important; }

.text-black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }

.text-white {
  color: #ffffff !important; }

.bg-white {
  background-color: #ffffff !important; }

.text-emergencyMainColor {
  color: #fb6475 !important; }

.bg-emergencyMainColor {
  background-color: #fb6475 !important; }

.text-emergencyLightColor {
  color: #ff929f !important; }

.bg-emergencyLightColor {
  background-color: #ff929f !important; }

.text-emergencyStrongColor {
  color: #ee4156 !important; }

.bg-emergencyStrongColor {
  background-color: #ee4156 !important; }

.text-purchasesMainColor {
  color: #f4a622 !important; }

.bg-purchasesMainColor {
  background-color: #f4a622 !important; }

.text-purchasesLightColor {
  color: #ffbb4c !important; }

.bg-purchasesLightColor {
  background-color: #ffbb4c !important; }

.text-purchasesStrongColor {
  color: #f78615 !important; }

.bg-purchasesStrongColor {
  background-color: #f78615 !important; }

.text-retirementMainColor {
  color: #c8bf28 !important; }

.bg-retirementMainColor {
  background-color: #c8bf28 !important; }

.text-retirementLightColor {
  color: #e3da43 !important; }

.bg-retirementLightColor {
  background-color: #e3da43 !important; }

.text-retirementStrongColor {
  color: #b5ab0b !important; }

.bg-retirementStrongColor {
  background-color: #b5ab0b !important; }

.opacity-100 {
  opacity: 1; }

.opacity-90 {
  opacity: 0.9; }

.opacity-80 {
  opacity: 0.8; }

.opacity-70 {
  opacity: 0.7; }

.opacity-60 {
  opacity: 0.6; }

.opacity-50 {
  opacity: 0.5; }

.opacity-40 {
  opacity: 0.4; }

.opacity-30 {
  opacity: 0.3; }

.opacity-20 {
  opacity: 0.2; }

.opacity-10 {
  opacity: 0.1; }

.pt-5 {
  padding-top: 5%; }

.mt-5 {
  margin-top: 5%; }

.pr-5 {
  padding-right: 5%; }

.mr-5 {
  margin-right: 5%; }

.pb-5 {
  padding-bottom: 5%; }

.mb-5 {
  margin-bottom: 5%; }

.pl-5 {
  padding-left: 5%; }

.ml-5 {
  margin-left: 5%; }

.pt-10 {
  padding-top: 10%; }

.mt-10 {
  margin-top: 10%; }

.pr-10 {
  padding-right: 10%; }

.mr-10 {
  margin-right: 10%; }

.pb-10 {
  padding-bottom: 10%; }

.mb-10 {
  margin-bottom: 10%; }

.pl-10 {
  padding-left: 10%; }

.ml-10 {
  margin-left: 10%; }

.pt-15 {
  padding-top: 15%; }

.mt-15 {
  margin-top: 15%; }

.pr-15 {
  padding-right: 15%; }

.mr-15 {
  margin-right: 15%; }

.pb-15 {
  padding-bottom: 15%; }

.mb-15 {
  margin-bottom: 15%; }

.pl-15 {
  padding-left: 15%; }

.ml-15 {
  margin-left: 15%; }

.pt-20 {
  padding-top: 20%; }

.mt-20 {
  margin-top: 20%; }

.pr-20 {
  padding-right: 20%; }

.mr-20 {
  margin-right: 20%; }

.pb-20 {
  padding-bottom: 20%; }

.mb-20 {
  margin-bottom: 20%; }

.pl-20 {
  padding-left: 20%; }

.ml-20 {
  margin-left: 20%; }

.pt-25 {
  padding-top: 25%; }

.mt-25 {
  margin-top: 25%; }

.pr-25 {
  padding-right: 25%; }

.mr-25 {
  margin-right: 25%; }

.pb-25 {
  padding-bottom: 25%; }

.mb-25 {
  margin-bottom: 25%; }

.pl-25 {
  padding-left: 25%; }

.ml-25 {
  margin-left: 25%; }

.pt-30 {
  padding-top: 30%; }

.mt-30 {
  margin-top: 30%; }

.pr-30 {
  padding-right: 30%; }

.mr-30 {
  margin-right: 30%; }

.pb-30 {
  padding-bottom: 30%; }

.mb-30 {
  margin-bottom: 30%; }

.pl-30 {
  padding-left: 30%; }

.ml-30 {
  margin-left: 30%; }

.pt-35 {
  padding-top: 35%; }

.mt-35 {
  margin-top: 35%; }

.pr-35 {
  padding-right: 35%; }

.mr-35 {
  margin-right: 35%; }

.pb-35 {
  padding-bottom: 35%; }

.mb-35 {
  margin-bottom: 35%; }

.pl-35 {
  padding-left: 35%; }

.ml-35 {
  margin-left: 35%; }

.pt-40 {
  padding-top: 40%; }

.mt-40 {
  margin-top: 40%; }

.pr-40 {
  padding-right: 40%; }

.mr-40 {
  margin-right: 40%; }

.pb-40 {
  padding-bottom: 40%; }

.mb-40 {
  margin-bottom: 40%; }

.pl-40 {
  padding-left: 40%; }

.ml-40 {
  margin-left: 40%; }

.pt-45 {
  padding-top: 45%; }

.mt-45 {
  margin-top: 45%; }

.pr-45 {
  padding-right: 45%; }

.mr-45 {
  margin-right: 45%; }

.pb-45 {
  padding-bottom: 45%; }

.mb-45 {
  margin-bottom: 45%; }

.pl-45 {
  padding-left: 45%; }

.ml-45 {
  margin-left: 45%; }

.pt-50 {
  padding-top: 50%; }

.mt-50 {
  margin-top: 50%; }

.pr-50 {
  padding-right: 50%; }

.mr-50 {
  margin-right: 50%; }

.pb-50 {
  padding-bottom: 50%; }

.mb-50 {
  margin-bottom: 50%; }

.pl-50 {
  padding-left: 50%; }

.ml-50 {
  margin-left: 50%; }

.pt-55 {
  padding-top: 55%; }

.mt-55 {
  margin-top: 55%; }

.pr-55 {
  padding-right: 55%; }

.mr-55 {
  margin-right: 55%; }

.pb-55 {
  padding-bottom: 55%; }

.mb-55 {
  margin-bottom: 55%; }

.pl-55 {
  padding-left: 55%; }

.ml-55 {
  margin-left: 55%; }

.pt-60 {
  padding-top: 60%; }

.mt-60 {
  margin-top: 60%; }

.pr-60 {
  padding-right: 60%; }

.mr-60 {
  margin-right: 60%; }

.pb-60 {
  padding-bottom: 60%; }

.mb-60 {
  margin-bottom: 60%; }

.pl-60 {
  padding-left: 60%; }

.ml-60 {
  margin-left: 60%; }

.pt-65 {
  padding-top: 65%; }

.mt-65 {
  margin-top: 65%; }

.pr-65 {
  padding-right: 65%; }

.mr-65 {
  margin-right: 65%; }

.pb-65 {
  padding-bottom: 65%; }

.mb-65 {
  margin-bottom: 65%; }

.pl-65 {
  padding-left: 65%; }

.ml-65 {
  margin-left: 65%; }

.pt-70 {
  padding-top: 70%; }

.mt-70 {
  margin-top: 70%; }

.pr-70 {
  padding-right: 70%; }

.mr-70 {
  margin-right: 70%; }

.pb-70 {
  padding-bottom: 70%; }

.mb-70 {
  margin-bottom: 70%; }

.pl-70 {
  padding-left: 70%; }

.ml-70 {
  margin-left: 70%; }

.pt-75 {
  padding-top: 75%; }

.mt-75 {
  margin-top: 75%; }

.pr-75 {
  padding-right: 75%; }

.mr-75 {
  margin-right: 75%; }

.pb-75 {
  padding-bottom: 75%; }

.mb-75 {
  margin-bottom: 75%; }

.pl-75 {
  padding-left: 75%; }

.ml-75 {
  margin-left: 75%; }

.pt-80 {
  padding-top: 80%; }

.mt-80 {
  margin-top: 80%; }

.pr-80 {
  padding-right: 80%; }

.mr-80 {
  margin-right: 80%; }

.pb-80 {
  padding-bottom: 80%; }

.mb-80 {
  margin-bottom: 80%; }

.pl-80 {
  padding-left: 80%; }

.ml-80 {
  margin-left: 80%; }

.pt-85 {
  padding-top: 85%; }

.mt-85 {
  margin-top: 85%; }

.pr-85 {
  padding-right: 85%; }

.mr-85 {
  margin-right: 85%; }

.pb-85 {
  padding-bottom: 85%; }

.mb-85 {
  margin-bottom: 85%; }

.pl-85 {
  padding-left: 85%; }

.ml-85 {
  margin-left: 85%; }

.pt-90 {
  padding-top: 90%; }

.mt-90 {
  margin-top: 90%; }

.pr-90 {
  padding-right: 90%; }

.mr-90 {
  margin-right: 90%; }

.pb-90 {
  padding-bottom: 90%; }

.mb-90 {
  margin-bottom: 90%; }

.pl-90 {
  padding-left: 90%; }

.ml-90 {
  margin-left: 90%; }

.pt-95 {
  padding-top: 95%; }

.mt-95 {
  margin-top: 95%; }

.pr-95 {
  padding-right: 95%; }

.mr-95 {
  margin-right: 95%; }

.pb-95 {
  padding-bottom: 95%; }

.mb-95 {
  margin-bottom: 95%; }

.pl-95 {
  padding-left: 95%; }

.ml-95 {
  margin-left: 95%; }

.pt-100 {
  padding-top: 100%; }

.mt-100 {
  margin-top: 100%; }

.pr-100 {
  padding-right: 100%; }

.mr-100 {
  margin-right: 100%; }

.pb-100 {
  padding-bottom: 100%; }

.mb-100 {
  margin-bottom: 100%; }

.pl-100 {
  padding-left: 100%; }

.ml-100 {
  margin-left: 100%; }

.padding-top-zero {
  padding-top: 0px !important; }

.padding-bottom-zero {
  padding-bottom: 0px !important; }

.padding-left-zero {
  padding-left: 0px !important; }

.padding-right-zero {
  padding-right: 0px !important; }

.padding-horiz-zero {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.padding-vert-zero {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.padding-all-zero {
  padding: 0px !important; }

.padding-top-tiny {
  padding-top: 5px !important; }

.padding-bottom-tiny {
  padding-bottom: 5px !important; }

.padding-left-tiny {
  padding-left: 5px !important; }

.padding-right-tiny {
  padding-right: 5px !important; }

.padding-horiz-tiny {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.padding-vert-tiny {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.padding-all-tiny {
  padding: 5px !important; }

.padding-top-small {
  padding-top: 10px !important; }

.padding-bottom-small {
  padding-bottom: 10px !important; }

.padding-left-small {
  padding-left: 10px !important; }

.padding-right-small {
  padding-right: 10px !important; }

.padding-horiz-small {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.padding-vert-small {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.padding-all-small {
  padding: 10px !important; }

.padding-top-normal {
  padding-top: 15px !important; }

.padding-bottom-normal {
  padding-bottom: 15px !important; }

.padding-left-normal {
  padding-left: 15px !important; }

.padding-right-normal {
  padding-right: 15px !important; }

.padding-horiz-normal {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.padding-vert-normal {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.padding-all-normal {
  padding: 15px !important; }

.padding-top-medium {
  padding-top: 20px !important; }

.padding-bottom-medium {
  padding-bottom: 20px !important; }

.padding-left-medium {
  padding-left: 20px !important; }

.padding-right-medium {
  padding-right: 20px !important; }

.padding-horiz-medium {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.padding-vert-medium {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.padding-all-medium {
  padding: 20px !important; }

.padding-top-large {
  padding-top: 30px !important; }

.padding-bottom-large {
  padding-bottom: 30px !important; }

.padding-left-large {
  padding-left: 30px !important; }

.padding-right-large {
  padding-right: 30px !important; }

.padding-horiz-large {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.padding-vert-large {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.padding-all-large {
  padding: 30px !important; }

.padding-top-xlarge {
  padding-top: 50px !important; }

.padding-bottom-xlarge {
  padding-bottom: 50px !important; }

.padding-left-xlarge {
  padding-left: 50px !important; }

.padding-right-xlarge {
  padding-right: 50px !important; }

.padding-horiz-xlarge {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.padding-vert-xlarge {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.padding-all-xlarge {
  padding: 50px !important; }

.margin-top-zero {
  margin-top: 0px !important; }

.margin-bottom-zero {
  margin-bottom: 0px !important; }

.margin-left-zero {
  margin-left: 0px !important; }

.margin-right-zero {
  margin-right: 0px !important; }

.margin-horiz-zero {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.margin-vert-zero {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.margin-all-zero {
  margin: 0px !important; }

.margin-top-tiny {
  margin-top: 5px !important; }

.margin-bottom-tiny {
  margin-bottom: 5px !important; }

.margin-left-tiny {
  margin-left: 5px !important; }

.margin-right-tiny {
  margin-right: 5px !important; }

.margin-horiz-tiny {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.margin-vert-tiny {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.margin-all-tiny {
  margin: 5px !important; }

.margin-top-small {
  margin-top: 10px !important; }

.margin-bottom-small {
  margin-bottom: 10px !important; }

.margin-left-small {
  margin-left: 10px !important; }

.margin-right-small {
  margin-right: 10px !important; }

.margin-horiz-small {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.margin-vert-small {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.margin-all-small {
  margin: 10px !important; }

.margin-top-normal {
  margin-top: 15px !important; }

.margin-bottom-normal {
  margin-bottom: 15px !important; }

.margin-left-normal {
  margin-left: 15px !important; }

.margin-right-normal {
  margin-right: 15px !important; }

.margin-horiz-normal {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.margin-vert-normal {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.margin-all-normal {
  margin: 15px !important; }

.margin-top-medium {
  margin-top: 20px !important; }

.margin-bottom-medium {
  margin-bottom: 20px !important; }

.margin-left-medium {
  margin-left: 20px !important; }

.margin-right-medium {
  margin-right: 20px !important; }

.margin-horiz-medium {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.margin-vert-medium {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.margin-all-medium {
  margin: 20px !important; }

.margin-top-large {
  margin-top: 30px !important; }

.margin-bottom-large {
  margin-bottom: 30px !important; }

.margin-left-large {
  margin-left: 30px !important; }

.margin-right-large {
  margin-right: 30px !important; }

.margin-horiz-large {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.margin-vert-large {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.margin-all-large {
  margin: 30px !important; }

.margin-top-xlarge {
  margin-top: 50px !important; }

.margin-bottom-xlarge {
  margin-bottom: 50px !important; }

.margin-left-xlarge {
  margin-left: 50px !important; }

.margin-right-xlarge {
  margin-right: 50px !important; }

.margin-horiz-xlarge {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.margin-vert-xlarge {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.margin-all-xlarge {
  margin: 50px !important; }

.margin-horiz-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

button:focus, button:active, button:focus:active {
  outline: none;
  box-shadow: none; }

button.plain-button {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: #7d8484; }
  button.plain-button:hover {
    background: none;
    color: #333333; }

a.btn {
  line-height: 38px !important; }

.btn {
  width: auto;
  display: inline-block;
  padding: 6px 30px;
  margin-top: 10px;
  margin-bottom: 10px; }
  .btn:focus, .btn:active, .btn:focus:active {
    outline: none;
    box-shadow: none; }
  .btn.btn-auto {
    width: auto !important;
    min-width: 0px !important; }
  .btn.btn-purple {
    background-color: #727ce2;
    border-color: #727ce2;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px; }
    .btn.btn-purple:hover, .btn.btn-purple:focus {
      background-color: #636bc3;
      border-color: #636bc3; }
  .btn.btn-purple-secondary {
    background-color: #ffffff;
    border: 3px solid #727ce2;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: #727ce2;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600; }
    .btn.btn-purple-secondary:hover, .btn.btn-purple-secondary:focus {
      background-color: #ffffff;
      border: 3px solid #636bc3;
      color: #636bc3; }
    .btn.btn-purple-secondary.selected {
      background-color: #f4a62f;
      border-color: #f4a62f;
      color: #ffffff;
      font-weight: 600; }
      .btn.btn-purple-secondary.selected:hover, .btn.btn-purple-secondary.selected:focus {
        background-color: #da880c;
        border-color: #da880c; }
  .btn.btn-orange {
    background-color: #f4a62f;
    border-color: #f4a62f;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600; }
    .btn.btn-orange:hover, .btn.btn-orange:focus {
      background-color: #da880c;
      border-color: #da880c; }
  .btn.btn-orange-secondary {
    background-color: #ffffff;
    border-color: #f4a62f;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: #f4a62f;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600; }
    .btn.btn-orange-secondary:hover, .btn.btn-orange-secondary:focus {
      background-color: #da880c;
      border-color: #da880c;
      color: #ffffff; }
  .btn.btn-green {
    background-color: #15D3BC;
    border-color: #15D3BC;
    min-height: 50px;
    min-width: 175px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600; }
    .btn.btn-green:hover, .btn.btn-green:focus {
      background-color: #10a291;
      border-color: #10a291; }

a.plain-link {
  color: #7d8484;
  text-decoration: none;
  cursor: pointer; }
  a.plain-link:focus, a.plain-link:active, a.plain-link:focus:active {
    color: #333333;
    outline: none;
    box-shadow: none; }
  a.plain-link:hover {
    color: #333333;
    text-decoration: none; }

.resource-step {
  z-index: 100; }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h1 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333333; }
  h1.x2 {
    font-size: 36px;
    line-height: 46px;
    font-weight: 600;
    color: #333333; }

h2 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700; }

.intro {
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #7d8484;
  margin: 25px 0; }

p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300; }

.text-small {
  font-size: 14px;
  line-height: 18px; }

.text-tiny {
  font-size: 10px;
  line-height: 14px; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-normal {
  font-weight: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.text-underline {
  text-decoration: underline !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.align-middle {
  vertical-align: middle !important; }

.align-top {
  vertical-align: top !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-baseline {
  vertical-align: baseline !important; }

.inline {
  display: inline !important; }

.inline-block {
  display: inline-block !important; }

.block {
  display: block !important; }

.d-none {
  display: none !important; }

.d-flex {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  flex-direction: row; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.align-items-start {
  align-items: flex-start; }

.align-items-end {
  align-items: flex-end; }

.align-items-center {
  align-items: center; }

.reverse-column {
  display: flex;
  flex-direction: column-reverse; }

.help-block {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300; }
  .help-block.help-error {
    color: #EE4256; }

.has-error span.help-block {
  color: #EE4256; }

.rbt-input-hint {
  font-weight: 300; }

input[type=file].custom-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }
  input[type=file].custom-file + label {
    color: #ffffff;
    background: #f4a62f;
    border: 1px solid #f4a62f;
    min-width: 175px;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    width: auto;
    display: inline-block;
    padding: 8px 55px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center; }
    input[type=file].custom-file + label.purple {
      background: #727ce2;
      border-color: #727ce2; }

input.datepicker[type=text], input.datepicker[type=number] {
  outline: none;
  box-shadow: none;
  border: 2px solid #EEEDED;
  border-radius: 4px;
  height: 50px;
  font-size: 22px;
  background-color: #EEEDED;
  color: #7d8484;
  font-weight: 300; }
  input.datepicker[type=text]:focus, input.datepicker[type=number]:focus {
    box-shadow: none; }

input.datepicker[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input.datepicker[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.control-label.price-header {
  font-size: 14px; }

.form-control {
  border: 1px solid #C89514;
  font-size: 16px;
  padding: 12px;
  height: auto;
  border-radius: 0;
  color: #B5812B;
  background: transparent; }

.help-block {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: red; }

.login-container li {
  font-size: 13px; }

.login-container h3 {
  font-size: 18px; }
  .login-container h3.bold-n-gold {
    color: #C89514;
    font-weight: bold; }

.main-nav.navbar {
  background-color: #0F0F0F;
  border-radius: 0;
  border: none; }

.navbar-brand {
  width: 150px;
  height: auto; }

.nav {
  width: 100%; }
  @media (min-width: 768px) {
    .nav.navbar-nav {
      float: none;
      margin: 0; } }
  .nav li {
    text-align: center; }
    .nav li a.nav-item {
      display: block;
      font-size: 12px;
      font-weight: 600;
      margin-top: 10px;
      text-transform: uppercase;
      color: #B5812B; }
    .nav li a svg, .nav li a {
      opacity: 1; }
    @media (min-width: 768px) {
      .nav li.logout {
        float: right; } }
  .nav.navbar-right {
    position: relative;
    top: 15px; }
  .nav .avi {
    position: relative;
    top: 4px; }
    .nav .avi a {
      padding: 10px 0; }

.moc {
  max-width: 1000px;
  margin: 0 auto; }

.wizard-nav {
  display: inline-block;
  margin-bottom: 15px; }
  .wizard-nav li {
    display: inline-block;
    padding: 4px; }
    @media (min-width: 991px) {
      .wizard-nav li {
        padding: 10px 10px 5px 10px; } }
    .wizard-nav li:hover {
      opacity: 0.7; }
    .wizard-nav li a {
      display: block;
      text-decoration: none; }
    .wizard-nav li svg {
      width: 25px;
      height: 25px; }
      @media (min-width: 991px) {
        .wizard-nav li svg {
          width: 40px;
          height: 40px; } }
  .wizard-nav .nav-item-label {
    display: block;
    font-size: 7px;
    font-weight: 600;
    margin-top: 10px;
    color: #F6F6F6; }
    @media (min-width: 991px) {
      .wizard-nav .nav-item-label {
        font-size: 12px; } }

.signup-wizard .page-body a {
  color: #C89514;
  text-decoration: underline; }

.signup-wizard .dropdown-menu {
  border-radius: 0; }

.signup-wizard a.dropdown-item {
  text-decoration: none;
  color: #161616;
  font-size: 18px; }

.signup-wizard .nav-controls {
  margin: 0 20px; }

.signup-wizard .spaced {
  margin: auto; }
  @media (min-width: 992px) {
    .signup-wizard .spaced {
      width: 70%; } }
  .signup-wizard .spaced .form-group {
    /*
            ***Commenting this out for now. The task says "Remove bullets" just want to make sure this is it before I remove them altogether.***
            
            >label:before {
                content: "";
                display: block;
                position: absolute;
                margin-left: -109px;
                margin-top: 0px;
                border-radius: 7px;
                background-image: url(/img/arrow-head.png);
                width: 26px;
                height: 20px;
            }
            */ }
    .signup-wizard .spaced .form-group .col-sm-6 {
      padding-left: 0; }
    .signup-wizard .spaced .form-group > label {
      margin-bottom: 20px; }
    @media (min-width: 992px) {
      .signup-wizard .spaced .form-group .answer-options {
        margin-left: 40px; } }

.signup-wizard .typeahead-close-container {
  position: relative; }

.signup-wizard .typeahead-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer; }

.signup-wizard .radio-select {
  background-color: transparent;
  border: 1px solid #C89514;
  padding: 6px 16px;
  color: #C89514;
  border-radius: 3px;
  min-width: 100%;
  font-weight: bold;
  margin: 4px 0; }
  @media (min-width: 992px) {
    .signup-wizard .radio-select {
      background-color: transparent;
      border: 1px solid #C89514;
      padding: 6px 16px;
      color: #C89514;
      border-radius: 3px;
      min-width: 80px;
      margin-right: 10px;
      font-weight: bold; } }
  .signup-wizard .radio-select:hover {
    border-color: #f7b241;
    color: #f7b241; }
  .signup-wizard .radio-select.active {
    background-color: #C89514;
    color: white; }

.navbar {
  margin-bottom: 0; }

body {
  background: url("/img/dark_exa.png") repeat;
  background-color: black; }

h3 {
  margin-top: 30px; }
  h3 .with-highlight {
    text-align: center;
    color: #F6F6F6;
    text-transform: none;
    font-weight: normal;
    text-transform: capitalize; }
    h3 .with-highlight span {
      color: #C89514;
      text-transform: capitalize; }

.page-header {
  padding: 15px 0;
  margin: 0;
  border-bottom: none; }
  .page-header .container {
    max-width: 1000px;
    margin: auto; }
  .page-header .title-icon {
    text-align: center; }
    .page-header .title-icon svg {
      width: 45px;
      height: 45px;
      margin-top: 20px; }
  .page-header h1 {
    text-align: center;
    color: #F6F6F6;
    text-transform: none;
    font-size: 22px;
    line-height: 25px;
    font-weight: normal;
    text-transform: capitalize;
    margin-top: 0px; }
    @media (min-width: 992px) {
      .page-header h1 {
        font-size: 30px;
        line-height: 35px; } }
  .page-header h3 {
    color: #9c9c9c;
    font-weight: 100;
    font-size: 16px;
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .page-header h3 {
        font-size: 20px; } }
    .page-header h3:after {
      content: "";
      background: url(/img/arrow.png) no-repeat;
      width: 90px;
      height: 30px;
      display: block;
      margin: 20px auto 0;
      border-radius: 5px; }
  .page-header h1 span, .page-header h3 span {
    color: #C89514;
    text-transform: capitalize; }
  .page-header h1 small {
    text-transform: capitalize; }
  .page-header h1 small {
    color: #F6F6F6; }
  .page-header .progressbar-container {
    width: 310px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .page-header .progressbar-container {
        width: 51%;
        margin: 0 auto; } }
    .page-header .progressbar-container .progress {
      height: 6px;
      background-color: #303030;
      margin-bottom: 0; }
      .page-header .progressbar-container .progress .progress-bar {
        background-color: #C89514; }

.page-body {
  margin: auto; }
  .page-body .container {
    max-width: 1000px;
    margin: auto;
    padding: 25px 20px; }
    .page-body .container label {
      font-size: 1.3em;
      font-weight: normal;
      color: #F6F6F6; }
      .page-body .container label span {
        color: #C89514; }
    .page-body .container input, .page-body .container select, .page-body .container textarea, .page-body .container .rbt-input-multi, .page-body .container .rbt-input {
      border: 1px solid #C89514;
      font-size: 16px;
      padding: 12px;
      height: auto;
      border-radius: 0;
      color: #B5812B;
      background: #000; }
    .page-body .container .rbt-token {
      background-color: transparent;
      color: #C89514;
      border: 1px solid #C89514;
      margin: 5px; }
    .page-body .container select {
      height: 50px; }
    .page-body .container .btn-primary {
      color: #F6F6F6;
      background-color: #C89514;
      border: none;
      border-radius: 3px;
      padding: 8px 35px 8px 10px;
      position: relative; }
      @media (min-width: 992px) {
        .page-body .container .btn-primary {
          padding: 8px 75px 8px 30px; } }
      .page-body .container .btn-primary:hover {
        background-color: #eab327; }
      .page-body .container .btn-primary.attachment {
        padding: 8px 55px;
        text-transform: none; }
    .page-body .container .login-form .btn-primary {
      padding: 8px 75px; }
    @media (min-width: 700px) {
      .page-body .container .login-form .login-container {
        margin: 0 auto; } }
    .page-body .container .btn-inverted {
      color: #B5812B;
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid #eab327;
      border-radius: 3px;
      padding: 7px 10px 7px 35px;
      position: relative; }
      @media (min-width: 992px) {
        .page-body .container .btn-inverted {
          padding: 7px 30px 7px 75px; } }
      .page-body .container .btn-inverted:hover {
        background-color: #eab327;
        color: #fff; }
    .page-body .container .login-feedback, .page-body .container .signup-feedback {
      background-color: red;
      padding: 5px 15px 5px 15px;
      margin: 20px;
      border-radius: 3px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    .page-body .container .icon {
      color: #C89514;
      width: 20px; }
      .page-body .container .icon.pass {
        color: greenyellow; }
      .page-body .container .icon.fail {
        color: red; }
      .page-body .container .icon.neutral {
        color: #3f3e3e; }
    .page-body .container div {
      color: #F6F6F6; }
    .page-body .container .tag {
      width: auto;
      padding: 5px !important;
      border-radius: 15px !important;
      font-size: 1em !important;
      min-width: auto !important;
      min-height: auto !important;
      margin-right: 5px;
      background-color: transparent !important;
      border: 2px solid #C89514 !important; }
      .page-body .container .tag.active {
        background-color: #C89514 !important; }

.react-grid-Toolbar {
  height: 65px; }

.progress.file-progress {
  background-color: #303030;
  height: 45px;
  width: 425px;
  border: 1px solid #C89514;
  border-radius: 0;
  float: left; }
  .progress.file-progress .progress-bar {
    background-color: #443206;
    line-height: 45px;
    vertical-align: middle; }

.modal-body .btn {
  border-radius: 3px !important; }

