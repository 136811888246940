.main-nav.navbar{
	background-color: $background-primary;
    border-radius: 0;
	border: none;
	// border-bottom: 1px solid #F5F0EC;
	// -webkit-box-shadow: 0px 1px 10px #F5F0EC;
	//         box-shadow: 0px 1px 10px #F5F0EC;
}

.navbar-brand{
	width: 150px;
	height: auto;		
}

.nav{
	width: 100%;
	@media(min-width: 768px){
		&.navbar-nav {
			float: none;
			margin: 0;
		}
	}
	li {
		text-align: center;
		
		a.nav-item {
			display: block;
			font-size: 12px;
			font-weight: 600;
			margin-top: 10px;
			text-transform: uppercase;
			color: $nav-text;
		}
	
		a svg, a {
			opacity: 1;
		}

		@media(min-width: 768px){
			&.logout{
				float: right;
			}
		}
	}

	&.navbar-right{
		position: relative;
		top: 15px;
	}

	.avi{
		position: relative;
		top: 4px;

		a{
			padding: 10px 0;
		}
	}
} 

.moc{
	max-width:1000px;
	margin:0 auto;
	
}
