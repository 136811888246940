.rbt-input-main, .rbt-input-multi, .form-control {
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #C89514;
  color: black;
  padding: 8px;
  height: auto;
  /* margin-bottom: 32px; */
}

.field-container {
  margin: 20px 0;
}

.field-container .field-label:nth-child(n+3) {
  padding-top: 15px;
}

.field-label {
  font-size: 16px;
  color: black;
  margin-bottom: 8px;
}

.field-label.field-inline {
  display: inline-block;
  margin-right: 1em;
}

.small-toggle {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  color: #C89514;
}

.rbt-token {
  background-color: transparent;
  color: #C89514;
  border: 1px solid #C89514;
  border-radius: 0;
  margin: 5px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  padding: 20px;
  border-radius: 0;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.title {
  color: #C89514;
  font-size: 20px;
  margin-bottom: 16px;
}

.actions .btn {
  margin-left: 5px;
}

.btn-primary {
  color: #fff;
  background-color: #C89514;
  border: none;
  border-radius: 0;
  padding: 10px 55px;
  text-transform: uppercase;
  position: relative;
}

.btn-primary:hover {
  background-color: #eab327;
}

.btn-inverted {
  color: #eab327;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #eab327;
  border-radius: 0;
  padding: 9px 55px;
  text-transform: uppercase;
  position: relative;
}

.btn-inverted:hover {
  background-color:#eab327;
  color: #fff;
}

.btn.btn-default .svg-inline--fa {
  margin-right: 10px;
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.checkbox-container.toggle {
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}

.field-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;
  cursor: pointer;
}

.field-checkbox.checkbox-active {
  color: #C89514;
}

.field-checkbox.checkbox-active .checkbox-label span {
  border-bottom: 2px solid #C89514;
}

.checkbox-icon {
  font-size: 16px;
  margin: 5px;
}

.checkbox-label {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.remove-button {
  margin-top: 6px;
}

.add-button, .remove-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-button .btn, .remove-button .btn {
  border: none;
  background-color: transparent;
  color: #C89514;
}